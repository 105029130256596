let testimonialSelector = 'testimonial_list';
let $testimonialCarousel;

if(document.getElementById(testimonialSelector)) {
  $testimonialCarousel = $(`#${testimonialSelector}`);

  $testimonialCarousel.owlCarousel({
    items:      1,
    nav:        false,
    loop:       false,
    mouseDrag:  false,
    animateIn:  'fadeIn',
    animateOut: 'fadeOut',
    responsive:{
        0:{
            items:1,
            dots:true,
        },
        767:{
        	items: 1,
          dots: false
        }
    },

    onInitialized: (function(e) {
      this.$element.addClass('owl-carousel');
      this.$element.find('.cloned, .owl-nav').attr({
          'aria-hidden': true,
          'role'       : 'presentation',
      });

      if(this.$element.find('.owl-dots .owl-dot').length > 1) {

        this.$element.find('.owl-dots').wrap('<div class="navigation"></div>').before('<span class="nav--left"></span><span class="nav--right"></span>');
      }
      this.$element.find('.nav--left').click(function(e){
          e.preventDefault();
          $testimonialCarousel.trigger('prev.owl.carousel');
      });

      this.$element.find('.nav--right').click(function(e){
          e.preventDefault();
          $testimonialCarousel.trigger('next.owl.carousel');
      });
    })
  })


$('.testimonial_list__nav li a').click(function(e){
    e.preventDefault();
    var itemIndex = $(this).data("item");
    $('.testimonial_list__nav li a.active').removeClass('active');
    $(this).addClass('active');
    $(`#${testimonialSelector}`).trigger('to.owl.carousel', itemIndex);
  });

}
import $ from 'jquery';

import store from '../../js/store';
import {debounce} from 'throttle-debounce';
import {disableScroll, enableScroll} from '../../js/disable-scroll';



const navActiveClass  = 'app--nav-active',
      desktopNavClass = 'app--desktop-nav',
      mobileNavClass  = 'app--mobile-nav',
      searchActiveClass  = 'app--search-active',

      $nav            = $('#site-nav'),
      $siteHead       = $('#site-head__main');

let viewportWidth = 0,
    width         = 0,
    isMobile      = false,
    doScroll      = true;



store.$app.on('click', '#site-nav__toggle', function(e) {
    e.preventDefault();

    const $this  = $(this),
          active = !store.$app.hasClass(navActiveClass);

    toggleMenu(active);
});


const toggleMenu = (function(open = true) {
  if(open) {

    $('#site-nav__toggle').attr({'aria-pressed': 'true', 'aria-expanded': 'true', 'title': 'Close Menu'});
    store.$app.addClass(navActiveClass);

    //setTimeout(disableScroll, 333);
    //doScroll = false;

    $nav.attr('aria-hidden', 'false');

    if(isMobile) {
      $('#site-main, #site-foot').attr({'aria-hidden': 'true'});
    }

    //disableScroll();

  } else {

    $('#site-nav__toggle').attr({'aria-pressed': 'false', 'aria-expanded': 'false', 'title': 'Open Menu'});
    store.$app.removeClass(navActiveClass);

    //enableScroll();
    //doScroll = true;

    $('#site-main, #site-foot').attr('aria-hidden', 'false');

    if(isMobile) {
      $nav.attr({'aria-hidden': 'false'});
    }
  }
});


export const checkNavSize = (function(forceCheck = false) {
    if(viewportWidth === window.innerWidth && !forceCheck) { return false; }

    setNavClass();

    const siteHeadWidth = parseInt($siteHead.width());
    width = 0;

    $siteHead.children(':visible').each((i, elem) => {
      width += parseInt($(elem).innerWidth());
    });

    isMobile = width > siteHeadWidth;

    setNavClass(isMobile);
    viewportWidth = window.innerWidth;
})


const setNavClass = (function(mobile = false) {
  store.$app.addClass(mobile ? mobileNavClass : desktopNavClass);
  store.$app.removeClass(mobile ? desktopNavClass : mobileNavClass);

  $('#site-nav__toggle').attr('aria-hidden', !mobile);

  toggleMenu(false);
});


const countryPicker = (function(e) {

  $(document).on('touchend click', function(e) 
  {
      var container = $(".country-picker");

      if (!container.is(e.target) && container.has(e.target).length === 0) 
      {
          container.removeClass('active');
      }
  });

  $(document).keyup(function(e) {
    if (e.keyCode === 27) $(".country-picker").removeClass('active');
  });

  $('.country-picker').on('click', function(event) {
    $(this).toggleClass('active');
  });

});

const searchToggle = (function(e) {

  $('.search__toggle').on('click', function(event) {

    store.$app.toggleClass(searchActiveClass);
  });

});

searchToggle();
checkNavSize();
countryPicker();
store.$window.on('resize', debounce(100, checkNavSize));
store.webFontLoaded.push([checkNavSize, true]);

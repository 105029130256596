import $ from 'jquery';
import store from '../../js/store';

const selectors = '#all-highlights, #news-highlights, #insta-highlights, #yt-highlights, #fb-highlights, #tw-highlights';

store.$app.on('click', '.filter__social a', function(e) {
  e.preventDefault();

  const id = this.hash,
        $this = $(this);

  if(!id || !$(id)[0]) {
    return false;
  }

  $(selectors).hide().attr('aria-hidden', 'true');

  console.log($(selectors));

  $this.closest('.filter__social').find('li').removeClass('active').find('a').attr({'aria-pressed':'false', 'aria-expanded':'false'});
  $this.attr({'aria-pressed':'true', 'aria-expanded':'true'}).closest('li').addClass('active');

  $(id).show().attr('aria-hidden', 'false');
});

import store from './js/store';

import './templates/page-head/home';
import './templates/site-nav';
import './templates/site-head';
import './templates/cookie-bar';
import './templates/page-content/product-list';
import './templates/page-content/testimonial-list';
import './templates/page-content/news-events';
import './templates/page-content/customers';
import './templates/page-content/integrations';
import './templates/page-content/resources';
import './templates/page-content/case_studies';
import './templates/page-content/benefits';

import './templates/social-wall';

import $ from 'jquery';
import 'owl.carousel';
import 'lity';
import 'jquery-match-height';
import WebFont from 'webfontloader';
import {debounce} from 'throttle-debounce';

import AOS from 'aos';

AOS.init({
  duration:1200,
  easing: 'ease-in-out',
});


WebFont.load({
    google: {
      families: ['Open Sans', 'Rubik']
    },
    fontactive: (familyName, fvd) => {
        $.each(store.webFontLoaded, (i, func) => {
            switch(typeof(func)) {
              case 'function':
                func();
                break;
              case 'object':
                func[0](...func[1])
                break;;
            }
        });
    }
});


function navSelect() {
    var selects = document.querySelectorAll('[data-nav-select]');

    for(var i=0;i<selects.length;i++) {
        selects[i].addEventListener('change', function(e){
            e.preventDefault();
            window.location.href = this.options[this.selectedIndex].value;
        });
    }
};

navSelect();



$('.inset-label .text-input, .inset-label textarea, .inset-label select').on('change keyup focus blur', function(e) {
  if($(this).val() || e.type === 'focus') {
    $(this).closest('.inset-label').addClass('focused');
  } else {
    $(this).closest('.inset-label').removeClass('focused');
  }
});


if(document.getElementById('article__content')) {
function get_text(el) {
  var ret = "";
  var length = el.childNodes.length;
  for (var i = 0; i < length; i++) {
    var node = el.childNodes[i];
    if (node.nodeType != 8) {
      ret += node.nodeType != 1 ? node.nodeValue : get_text(node);
    }
  }
  return ret;
}

var words = get_text(document.getElementById('article__content'));
var count = words.split(' ').length;
var wordsperminute = 200;
var readingtime = count / wordsperminute;
readingtime = +readingtime.toFixed(1);


$('.read-time').text(Math.round(readingtime));

}

export const fixedScroll = (function(e) {
  if(window.innerWidth <= 600 && document.getElementById('wpadminbar')) {
    var topNav = $('.site-head__top').outerHeight(true) + $('#wpadminbar').outerHeight(true);
  } else {
    var topNav = $('.site-head__top').outerHeight(true);
  }
  var mainNav = $('.site-head__main').outerHeight(true);

  if ($(window).scrollTop() >= topNav) {
     $('.site-head__main').addClass('fixed');
     $('.site-main').css('margin-top', mainNav);
  }
  else {
     $('.site-head__main').removeClass('fixed');
     $('.site-main').css('margin-top', 0);
  }
})

if ($("body").hasClass("archive")) {
 $('html, body').animate({
    scrollTop: $('#site-page').offset().top}, 1000);
     console.log('test');
}

store.$window.on('load resize scroll', debounce(0, fixedScroll));

$(document).ready(function(){
  $(".benefit-alt a").on('click', function(event) {
    var mainNav = $('.site-head__main').outerHeight(true);
    if (this.hash !== "") {

      event.preventDefault();


      var hash = this.hash;

      $('html, body').animate({
        scrollTop: $(hash).offset().top-mainNav
      }, 500, function(){
   
      });
    } 
  });
});


store.$app.on("click", "[data-lity]", function(e) {
  e.preventDefault();
});
let newsSelector = 'news__list';
let $newsCarousel;

if(document.getElementById(newsSelector)) {
  $newsCarousel = $(`#${newsSelector}`);

  $newsCarousel.owlCarousel({
    items:      1,
    dots:       false,
    nav:        false,
    loop:       false,
    mouseDrag:  false,
    animateIn:  'fadeIn',
    animateOut: 'fadeOut',
    responsive:{
        0:{
            items:1,
        },
        980:{
          items: 1,
        }
    },

    onInitialized: (function(e) {
      this.$element.addClass('owl-carousel');
      this.$element.find('.cloned, .owl-nav').attr({
          'aria-hidden': true,
          'role'       : 'presentation',
      });
    })
  })


$('.news_list__nav ul li').click(function(e){
    e.preventDefault();
    var itemIndex = $(this).data("item");
    $('.news_list__nav ul li.selected').removeClass('selected');
    $(this).addClass('selected');
    $(`#${newsSelector}`).trigger('to.owl.carousel', itemIndex);
  });

}
let productSelector = 'product_list';
let $productCarousel;

if(document.getElementById(productSelector)) {
  $productCarousel = $(`#${productSelector}`);

  $productCarousel.owlCarousel({
    items:      1,
    dots:       false,
    nav:        false,
    loop:       false,
    mouseDrag:  false,
    animateIn:  'fadeIn',
    animateOut: 'fadeOut',
    responsive:{
        0:{
            items:1,
        },
        980:{
          items: 1,
        }
    },

    onInitialized: (function(e) {
      this.$element.addClass('owl-carousel');
      this.$element.find('.cloned, .owl-nav').attr({
          'aria-hidden': true,
          'role'       : 'presentation',
      });
    })
  })


$('.product_list__nav li a').click(function(e){
    e.preventDefault();
    var itemIndex = $(this).data("item");
    $('.product_list__nav li a.active').removeClass('active');
    $(this).addClass('active');
    $(`#${productSelector}`).trigger('to.owl.carousel', itemIndex);
  });

}
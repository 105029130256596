$(document).ready(function(){ 
    var $listToFilter = $('.customer__list');    
    var $allElements = $listToFilter.find('.customer__list-item');
    var filterCache = {};
    
    function filterElements (filterClass) {
        var $elemetnsToFilter;
        
        if(filterCache.hasOwnProperty(filterClass)) {
            $elemetnsToFilter = filterCache[filterClass];
        } else {
            $elemetnsToFilter = $listToFilter.find('.' + filterClass);
            filterCache[filterClass] = $elemetnsToFilter;
        }
        
        $allElements.not($elemetnsToFilter).hide();
        $elemetnsToFilter.fadeIn();
    }
    
    
    $('.customer_list__nav ul li').click(function (e) {
        e.preventDefault();
        var filterClass = $(this).attr('data-customer');
        $('.customer_list__nav ul li.selected').removeClass('selected');
        $(this).addClass('selected');        
        filterElements(filterClass);
    });
    
    filterElements('customer__list--0');  

});

$('.customer__list-item').each(function(i, obj) {

    var i = String(i);

    var customerCarousel = $('.customer__list--'+i);

    customerCarousel.owlCarousel({
    items:      4,
    dots:       true,
    nav:        false,
    loop:       false,
    mouseDrag:  false,
    slideBy: 4,
    responsive:{
        0:{
            items:2,
            slideBy: 2,
        },
        767:{
          items: 3,
          slideBy: 3,
        },
        1024:{
            items: 4,
            slideBy: 4,
        }
    },

    onInitialized: (function(e) {
      this.$element.addClass('owl-carousel');
      this.$element.find('.cloned, .owl-nav').attr({
          'aria-hidden': true,
          'role'       : 'presentation',
      });

      if(this.$element.find('.owl-dots .owl-dot').length > 1) {

        this.$element.find('.owl-dots').wrap('<div class="navigation"></div>').before('<span class="nav--left"></span><span class="nav--right"></span>');
      }
	this.$element.find('.nav--left').click(function(e){
	    e.preventDefault();
	    customerCarousel.trigger('prev.owl.carousel');
	});

	this.$element.find('.nav--right').click(function(e){
	    e.preventDefault();
	    customerCarousel.trigger('next.owl.carousel');
	});

    })
    })        
});

module.exports={
    "colors": {
        "red": {
            "color": "#ec1b34",
            "text" : "#fff"
        },
        "blue": {
            "color": "#382489",
            "text" : "#fff"
        },
        "citnowblue": {
            "color": "#0426a1",
            "text" : "#fff"
        },
        "green": {
            "color": "#2ce1b7",
            "text" : "#fff"
        }, 
        "navy": {
            "color": "#001033",
            "text" : "#fff"
        },        
        "orange": {
            "color": "#f5731e",
            "text" : "#fff"
        },
        "light-gold": {
            "color": "#d2cfc3",
            "text" : "#fff"
        },
        "aftersales": {
            "color": "#1a2696",
            "text" : "#fff"
        },
        "appraise": {
            "color": "#013b9e",
            "text" : "#fff"
        },
        "bodyshop": {
            "color": "#079889",
            "text" : "#fff"
        },
        "conversations": {
            "color": "#034c9b",
            "text" : "#fff"
        },
        "imaging": {
            "color": "#045d97",
            "text" : "#fff"
        },
        "insights": {
            "color": "#282590",
            "text" : "#fff"
        },
        "kiosk": {
            "color": "#0c269d",
            "text" : "#fff"
        },
        "sales": {
            "color": "#06828e",
            "text" : "#fff"
        },
        "triage": {
            "color": "#012ba3",
            "text" : "#fff"
        },
        "workshop": {
            "color": "#046f93",
            "text" : "#fff"
        }
    },
    "sprout-breakpoints" : {
        "xsmall" :  "null",
        "small"  :  "480px",
        "medium" :  "720px",
        "large"  :  "980px",
        "xlarge" :  "1200px"
    }
}


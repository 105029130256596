import $ from 'jquery'
import constants from '../../../../../constants.json';

export default {
  resolution: window.devicePixelRatio || 1,
  $document: $(document),
  $window: $(window),
  $app: $('body'),
  body: document.getElementsByTagName('body')[0],
  webFontLoaded: [],
  constants,
  google: {
    key: 'AIzaSyALViAtKe1NK8aooy8y-m-bD95mKamrKVY'
  }
}

import $ from 'jquery';

let lastScrollPosition;

export function enableScroll(scrollPosition = null) {
    $('html').removeClass('disable-scroll');

    if(scrollPosition === false) {
        window.scrollTo(0, lastScrollPosition);
    }

    lastScrollPosition = null;
}

export function disableScroll() {
    lastScrollPosition = window.pageYOffset;

    $('html').addClass('disable-scroll');
}


var integrationCarousel = $('.integration__list');

integrationCarousel.owlCarousel({
    items:      4,
    dots:       true,
    nav:        false,
    loop:       false,
    mouseDrag:  false,
    slideBy: 4,
    responsive:{
        0:{
            items:2,
            slideBy: 2,
        },
        767:{
          items: 3,
          slideBy: 3,
        },
        1024:{
            items: 4,
            slideBy: 4,
        }
    },

    onInitialized: (function(e) {
      this.$element.addClass('owl-carousel');
      this.$element.find('.cloned, .owl-nav').attr({
          'aria-hidden': true,
          'role'       : 'presentation',
      });

      if(this.$element.find('.owl-dots .owl-dot').length > 1) {

        this.$element.find('.owl-dots').wrap('<div class="navigation"></div>').before('<span class="nav--left"></span><span class="nav--right"></span>');
      }
    this.$element.find('.nav--left').click(function(e){
        e.preventDefault();
        integrationCarousel.trigger('prev.owl.carousel');
    });

    this.$element.find('.nav--right').click(function(e){
        e.preventDefault();
        integrationCarousel.trigger('next.owl.carousel');
    });

    })
})        


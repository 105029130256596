import $ from 'jquery';
import 'owl.carousel';
import store from '../../js/store';

const selector = 'page-head__home-carousel';
let $carousel;

if(document.getElementById(selector)) {
  $carousel = $(`#${selector}`);

  $carousel.owlCarousel({
    items:      1,
    dots:       false,
    nav:        false,
    loop:       true,
    mouseDrag:  false,
    autoHeight: true,
    animateIn:  'fadeIn',
    animateOut: 'fadeOut',
    autoplay:true,
    autoplayTimeout:10000,
    responsive:{
        0:{
            items:1,
        }
    },

    onInitialized: (function(e) {
      this.$element.addClass('owl-carousel');
      this.$element.find('.cloned, .owl-nav').attr({
          'aria-hidden': true,
          'role'       : 'presentation',
      });
    }),
  });

  store.$app.on('click', `#${selector} .owl-dot`, function() {
    $carousel.trigger('to.owl.carousel', [$(this).index(), 300]);
  });

  store.$window.on('resize', function() {
    $carousel.find('.owl-height').css('height', $carousel.find('.owl-item.active').css('height'));
  });
}
